@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin link-style {
    color: $main-color;
    text-decoration: underline;
    word-break: break-all;

    &:hover {
        color: $active-color;
    }
}

@mixin field-label-hidden() {
    .label {
        display: none;
    }
}

@mixin field-label-display() {
    .label {
        display: initial;
    }
}

@mixin only-mobile($display) {
    display: none;

    @include media("<mobile") {
        display: $display;
    }
}

@mixin checkbox-input {
    input {
        display: none;
    }

    input + label {
        .icon_checkbox_on {
            display: none;
        }
    }

    input:checked + label {
        .icon_checkbox_off {
            display: none;
        }

        .icon_checkbox_on {
            display: inline-block;
        }
    }
}

@mixin field-box-style {
    display: flex;
    margin-bottom: rem(20);
    border: rem(1) solid $gray-1;
    border-radius: rem(4);

    &.column {
        flex: 1;
        flex-direction: column;
        overflow: hidden;

        & + .field-box {
            margin-left: rem(16);
        }

        .field + .field {
            border-top: rem(1) solid $gray-1;
            border-left: none;
        }
    }

    .field {
        flex: 1;
        position: relative;
        margin: 0;
        padding: rem(5) rem(15);

        &.row {
            align-items: center;
            min-height: rem(46);
        }

        &.action {
            background-color: $gray-0;
        }

        & + .field {
            border-left: rem(1) solid $gray-1;
        }

        input {
            width: 100%;
        }
    }

    .field-title {
        font-size: rem(16);
    }
}

@mixin field-style {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: rem(16);
    font-size: rem(16);

    &.row {
        flex-direction: row;
        min-height: $field-height-s;
        margin-bottom: rem(10);
        // align-items: center;

        .field-title {
            margin-bottom: 0;

            &:after {
                content: "";
                // content: ":";
                margin-right: rem(8);
            }
        }
    }

    &:not(.row) {
        .field-title {
            font-size: rem(17);
        }
    }

    input {
        // min-width: rem(300);
    }

    .field-title {
        flex-shrink: 0;
        // min-width: rem(120);
        margin-bottom: rem(8);
        font-weight: $font-weight-medium;
        line-height: initial;
    }

    .field-content {
        // display: flex;
        overflow: hidden;
        flex: 1;
        width: 100%;
        white-space: pre-wrap;
        color: $gray-4;

        p {
            line-height: inherit;
        }
    }
}

@mixin fieldset-style {
    fieldset {
        font-size: rem(16);

        h2 {
            font-size: rem(24);
        }

        a {
            text-decoration: underline;
            color: $main-color;
        }

        input[type="text"] {
            width: 100%;
        }
    }

    .field {
        @include field-style;
    }

    .field-box {
        @include field-box-style;
    }
}

@mixin filter-style {
    margin-bottom: rem(20);
    padding: rem(16) rem(20);
    border: rem(1) solid $gray-1;
    background-color: $white;
}

@mixin search-items-style {
    display: flex;
    flex-wrap: wrap;
    margin: rem(-8);
}

@mixin search-item-style($counts: "auto") {
    @if ($counts == "auto") {
        flex: 1;
    } @else {
        width: calc(#{(100 / $counts)}% - #{rem(12)});
    }
    margin: rem(6);
    padding: rem(12) rem(14);
    border-radius: rem(10);
    background-color: $gray-0;

    &.row {
        display: flex;
        align-items: center;

        h3,
        label {
            flex: 1;
            margin-right: rem(10);
            margin-bottom: 0;
        }
    }

    h3,
    label {
        display: block;
        margin-bottom: rem(10);
        font-size: rem(14);
        font-weight: $font-weight-bold;
    }

    .MuiInput-root,
    .MuiSelect-select,
    .MuiFormControl-root,
    input {
        width: 100%;
    }

    .MuiInput-input,
    .MuiSelect-select {
        padding-left: rem(4);
    }
}

@mixin search-btn-style {
    display: flex;
    justify-content: center;
    margin-top: rem(20);

    button {
        width: rem(120);

        & + button {
            margin-left: rem(12);
        }
    }
}

@mixin point-box-style {
    padding: rem(16) rem(20);
    // border: rem(10) solid $blue-light;
    border-radius: rem(10);
    border: rem(1) solid #ccdaff;
    // border-bottom: rem(2) solid $blue-light;
    // border-left: rem(10) solid $blue-light;
    // border-right: rem(10) solid $blue-light;
    background-color: $blue-lighter;
    font-size: rem(18);
    // margin: rem(-30) rem(-40) 0;
    box-shadow: rem(-3) 0 rem(10) 0 rgba($blue-light, 0.05);
}

@mixin area-style($color: "gray") {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: rem(12) rem(20);
    border-radius: rem(10);
    border: rem(1) solid $gray-1;
    background-color: $gray-0;

    @if ($color == "blue") {
        border-color: #ccdaff;
        background-color: $blue-lighter;
    }

    > * {
        flex-shrink: 0;
    }

    .field {
        margin-bottom: rem(8);
    }
}

@mixin label-btn-list-style {
    display: flex;
    flex-wrap: wrap;
    margin: rem(-3);

    > * {
        margin: rem(3);
    }

    label {
        display: inline-flex;
        align-items: center;
        height: $field-height-m;
        padding: rem(2) rem(8);
        border: rem(1) solid $gray-2;
        border-radius: rem(4);
        line-height: $field-height-m;
        cursor: pointer;
    }

    input {
        display: none;

        &:checked {
            & + label {
                background-color: $gray-3;
                color: $white;
            }
        }

        &:disabled {
            & + label {
                cursor: not-allowed;
            }
        }

        &:not(:checked):disabled {
            & + label {
                background-color: $gray-0;
                color: $gray-2;
            }
        }
    }
}

@mixin unread-mark($position: static) {
    @if ($position == "absolute") {
        position: relative;
    }

    &:after {
        content: "";
        display: inline-block;
        flex-shrink: 0;
        position: $position;
        top: 0;
        right: 0;
        width: rem(6);
        height: rem(6);
        margin: rem(2) rem(-5) 0 rem(2);
        border-radius: 50%;
        vertical-align: top;
        background-color: $red;

        @include media("<mobile") {
            width: mobile(10);
            height: mobile(10);
            margin: 0 mobile(-10) 0 mobile(4);
        }
    }
}

@mixin editor-text-style($size: "m") {
    line-height: 1.7;
    letter-spacing: rem(-0.8);
    white-space: pre-wrap;
    word-break: keep-all;
    color: $gray-3;

    @if ($size == "s") {
        font-size: rem(14);

        @include media("<mobile") {
            font-size: mobile(22);
        }
    } @else {
        font-size: rem(16);

        @include media("<mobile") {
            font-size: mobile(24);
        }
    }

    @include media("<mobile") {
        letter-spacing: mobile(-0.8);
    }

    p {
        line-height: 1.7;
    }

    a {
        @include link-style;
    }

    strong {
        font-weight: $font-weight-medium;
    }

    h3 {
        font-weight: $font-weight-normal;
        line-height: 1.7;

        @if ($size == "s") {
            font-size: rem(16);

            @include media("<mobile") {
                font-size: mobile(24);
            }
        } @else {
            font-size: rem(18);

            @include media("<mobile") {
                font-size: mobile(26);
            }
        }
    }

    img {
        display: block;
        max-width: 100%;
    }

    ul {
        list-style: disc;
    }

    ul,
    ol {
        padding-left: rem(20);

        @include media("<mobile") {
            padding-left: mobile(24);
        }

        li {
            padding-left: rem(2);
            line-height: 1.7;

            @include media("<mobile") {
                padding-left: mobile(2);
            }
        }

        li::before {
            content: "";
            display: none;
        }
    }

    ol {
        list-style: auto;

        li {
            list-style-type: auto;
        }
    }

    hr {
        border: none;
        border-top: rem(1) solid $gray-1;

        @include media("<mobile") {
            border-top-width: mobile(1);
        }
    }

    pre.ql-syntax {
        display: inline-block;
        max-width: 100%;
        margin: 0;
        padding: rem(20);
        border: rem(1) solid $gray-1;
        border-radius: rem(10);
        background-color: $gray-0;
        white-space: pre-wrap;
        word-break: break-all;
        color: inherit;

        @include media("<mobile") {
            padding: mobile(20) $indent-mobile;
            border-radius: mobile(20);
        }
    }

    blockquote {
        margin: 0;
        padding-left: rem(12);
        border-left: rem(4) solid $gray-1;
        color: $gray-5;

        @include media("<mobile") {
            padding-left: mobile(20);
            border-left-width: mobile(4);
        }
    }

    .ql-indent-1 {
        &:not(.ql-direction-rtl) {
            padding-left: rem(20);

            @include media("<mobile") {
                padding-left: $indent-mobile;
            }
        }
    }
}
