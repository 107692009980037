@import "styles/utils";

.image-crop-modal-contents {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: rem(700);
    height: rem(700);
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
        margin-top: rem(50);
    }
}
