@import "styles/utils";

#promotion-select-user {
    .select-type {
        display: flex;
        margin-bottom: rem(40);
        padding-bottom: rem(30);
        border-bottom: rem(1) solid $gray-1;

        &:not(.active) {
            display: none !important;
        }

        &.user {
            height: rem(300);
        }
    }

    .field.select-type-list {
        font-size: rem(18);
        font-weight: $font-weight-medium;

        label {
            display: inline-flex;
            align-items: center;
            margin-right: rem(20);
        }

        input {
            margin: 0 rem(8) 0 0;
        }
    }
}
