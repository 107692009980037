@import "styles/utils";

.manage-nav {
    ul {
        display: flex;

        li {
            background-color: $gray-0;
            padding: rem(10) rem(20);
            border: 1px solid $gray-1;
            border-bottom: none;
            font-size: rem(14);
            color: $gray-2;
            cursor: pointer;

            &.active {
                background-color: $white;
                font-weight: $font-weight-medium;
                color: $gray-5;
            }
        }

        li + li {
            border-left: none;
        }

        a {
            display: block;
            margin: rem(-10) rem(-20);
            padding: rem(10) rem(20);
        }
    }
}
