@import "styles/utils";

.subs-main-statistics-item {
    border: 1px solid $gray-5;
    width: 13%;
    height: rem(170);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: rem(10);

    h3 {
        font-size: $font-size-m;
        margin-bottom: rem(5);
    }

    .desc {
        font-size: $font-size-xs;
        margin-bottom: rem(50);
        line-height: rem(15);
    }

    .statistics-value {
        font-size: rem(30);
        font-weight: bold;
        color: $main-color;
    }
}
