@import "styles/utils";

#project-list {
    background-color: $white;
    border: rem(1) solid $gray-1;

    .project-status {
        display: flex;
        justify-content: center;

        &.rereviewing {
            @include unread-mark;
        }
    }
}
