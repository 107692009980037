@import "styles/utils";

#common-select-biz {
    display: flex;
    width: 100%;

    .icon_arrow_right {
        align-self: center;
        margin: 0 rem(12);
        font-size: rem(18);
    }

    .select-user-area,
    .selected-user-area {
        display: flex;
        flex-direction: column;
        flex: 1;
        flex-shrink: 0;

        .type-content {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow-y: auto;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
        }

        li {
            width: 50%;
            margin: rem(4) 0;

            button {
                min-width: auto;
            }
        }
    }

    .attach-user-box,
    .search-user-box {
        @include area-style;

        width: 100%;
    }

    .search-user-box {
        flex: 1;
    }

    .attach-user-box {
        flex-direction: row;
        align-items: center;
        margin-bottom: rem(12);

        .type-title {
            margin-right: rem(12);
        }
    }

    .selected-user-area,
    .search-user-box {
        .type-title {
            margin-bottom: rem(12);
        }
    }

    .selected-user-area {
        @include area-style($color: "blue");

        li {
            list-style: disc;
            list-style-position: inside;
        }
    }

    .type-title {
        font-size: rem(16);
        font-weight: $font-weight-medium;
    }

    .search-input-area {
        display: flex;
        align-items: center;
        margin-bottom: rem(12);

        input {
            margin-right: rem(12);
        }
    }

    .searched-result-area {
        overflow-y: auto;
        flex: 1;

        li {
            display: flex;
            align-items: center;
            cursor: pointer;

            button {
                margin-left: rem(12);
            }
        }
    }
}
