@import "styles/utils";

#add-feed-pick {
    .set-pick-area {
        display: flex;
        align-items: center;
        margin-bottom: rem(12);
        padding: rem(10) rem(16);
        border: rem(1) solid $gray-1;
        background-color: $white;
    }

    input {
        margin-right: rem(12);
    }
}
