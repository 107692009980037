@import "styles/utils";

.project-request-modal {
    @include modal-style;
    @include fieldset-style;

    .modal-contents {
        @include modal-content-style;
    }

    .status-list {
        @include label-btn-list-style;
    }

    .caution-text {
        width: 100%;
        margin: rem(8) 0;
        color: $red;
    }

    .etc-info {
        display: flex;
    }

    .field {
        &.status {
            padding: rem(16) rem(20);

            .field-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                // padding: rem(20);
            }
        }

        &.submittedAt {
            flex: none;
            min-width: rem(200);
        }

        &.submitEndAt {
            button {
                margin-left: rem(12);
            }
        }
    }

    textarea {
        margin-top: rem(12);
        height: rem(130);
    }

    .edit-submitted-info-btn {
        @include btn-clear-style;
        margin-bottom: rem(12);
        font-size: rem(16);
    }

    .new-submit-info {
        input[type="text"] {
            flex: 1;
            margin: 0 rem(12);
        }
    }

    hr {
        margin: 0 0 rem(20);
        border: none;
        border-bottom: rem(1) solid $gray-1;

        & + hr {
            display: none;
        }
    }

    a {
        @include link-style;
    }
}
