@import "styles/utils";

#charge-detail-modal {
    @include modal-style;
    @include fieldset-style;

    hr {
        margin: rem(30) 0;
        border: none;
        border-top: rem(1) solid $gray-1;
        background-color: $gray-1;
    }
}
