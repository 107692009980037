@import "styles/utils";

.message-item-content {
    position: relative;
    padding: rem(12) rem(14);
    font-size: rem(14);
    line-height: 1.7;
    white-space: pre-wrap;
    word-break: break-all;

    @include media("<mobile") {
        padding: mobile(16) mobile(24);
        font-size: mobile(28);
        color: $gray-5;
    }

    &.img {
        width: rem(290);
        padding: 0;

        @include media("<mobile") {
            width: mobile(430);
        }
    }

    &.text {
        a {
            @include link-style;
        }
    }

    &.file {
        display: flex;
        align-items: center;
        padding-top: rem(26);
        padding-bottom: rem(26);
        background-color: $white;

        @include media("<mobile") {
            padding-top: mobile(40);
            padding-bottom: mobile(40);
        }

        .rs-icon {
            margin-right: rem(8);
            font-size: rem(18);
            color: $yellow-dark;

            @include media("<mobile") {
                margin-right: mobile(24);
                font-size: mobile(36);
            }
        }

        & + .message-sub-info {
            margin-top: rem(10);

            @include media("<mobile") {
                margin-top: mobile(20);
            }
        }
    }

    &:not(:hover) {
        .image-info-box {
            opacity: 0;
        }
    }

    img {
        display: block;
        width: 100%;
    }
}
