@import "styles/utils";

.contact-us-detail {
    @include fieldset-style;

    .field.row {
        margin-bottom: 0;
        align-items: center;
    }

    .request-comment,
    .reply-comment {
        border-bottom: rem(1) solid $gray-2;
        padding: rem(20);
    }

    .request-comment {
        border-top: rem(1) solid $gray-2;
        background-color: $white;
    }

    .field {
        margin-bottom: 0;
    }

    .request-contents {
        @include editor-text-style;
        line-height: 1.5;
    }

    .attachment-list {
        margin-top: rem(20);

        .field-content {
            display: flex;
            flex-direction: column;
        }

        .attachment-item {
            flex-shrink: 0;
        }

        .attachment-item + .attachment-item {
            margin-top: rem(10);
        }

        a {
            @include link-style;
        }
    }
}
