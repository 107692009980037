@import "styles/utils";

.user-manage-list-wrapper {
    background-color: $white;
    border: rem(1) solid $gray-1;
    width: 100%;

    .table-menu {
        padding: rem(16) rem(20);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .table-area {
        max-width: rem(1630);
        overflow-x: auto;
        padding-bottom: rem(20);
    }

    .button-area {
        display: flex;
        flex-direction: row;

        button + button {
            margin-left: rem(10);
        }
    }
}
