@import "styles/utils";

.common-table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(16) rem(20);

    .count-items,
    .action-btn-items {
        display: flex;

        > * {
            & + * {
                margin-left: rem(8);
            }
        }
    }

    .count-items {
        li {
            display: flex;
            align-items: center;

            & + li {
                &:before {
                    content: "";
                    display: block;
                    width: rem(1);
                    height: rem(16);
                    margin: 0 rem(8);
                    background-color: $gray-2;
                }
            }
        }
    }

    .download-csv-btn {
        min-width: auto;
        margin-left: rem(8);
        padding-left: rem(8);
        padding-right: rem(8);
        white-space: pre-wrap;
    }
}
