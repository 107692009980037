@import "styles/utils";

.login {
    .login-header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: rem(27) 0;
        border-bottom: 1px solid $gray-1;

        .reviewshare-logo {
            width: rem(190);
            height: rem(24);
            margin-right: rem(8);
        }

        .admin-main-link {
            font-size: 20px;
            font-weight: bold;
        }
    }

    .login-title-img {
        text-align: center;
        padding-top: rem(140);
        margin: 0 auto;

        img {
            width: rem(157);
            height: rem(26);
        }
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        margin-top: rem(40);
        width: rem(360);

        > input {
            height: rem(38);
            border: 1px solid $gray-1;
            border-radius: $border-radius-m;
            font-size: rem(14);
            padding: 0 rem(17);

            &::placeholder {
                color: $gray-2;
            }
        }

        > input + input {
            margin-top: rem(4);
        }

        .login-btn {
            margin-top: rem(30);
        }

        button + button {
            margin-top: rem(8);
        }
    }

    .error-msg {
        p {
            margin-top: rem(8);
            color: $red-dark;
            font-size: rem(12);
            line-height: 1.43;
        }
    }

    .login-options {
        @include checkbox-input;
        margin-top: rem(12);

        label {
            display: flex;
            align-items: center;

            i {
                margin-right: rem(8);
                color: $gray-3;
            }

            p {
                font-size: rem(14);
                line-height: 1.43;
                color: $gray-3;
            }
        }
    }
}
