@import "styles/utils";

.subs-main-statistics {
    display: flex;
    margin-top: rem(20);

    > li + li {
        margin-left: rem(20);
    }
}
