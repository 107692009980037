@import "styles/utils";

#message-list {
    flex: 1;
    overflow-y: auto;
    height: rem(600);
    margin-left: rem(20);
    padding: rem(20);
    border: rem(1) solid $gray-1;

    .prev-btn {
        margin: 0 auto;
        padding-left: rem(10);
        padding-right: rem(10);
    }
}
