@import "styles/utils";

#talent-filter {
    @include filter-style;

    fieldset {
        @include search-items-style;
    }

    .field {
        @include search-item-style(4);
    }

    .button-box {
        @include search-btn-style;
    }
}
