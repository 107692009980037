@import "styles/utils";

#biz-subs-info {
    @include fieldset-style;

    .current {
        .history-item {
            border-color: #ccdaff;
            background-color: $blue-lightest;
        }
    }

    .isDeleted {
        color: $gray-2;

        .price-info {
            border-left-color: $gray-1;
        }

        .field-content {
            color: $gray-2;
        }
    }

    .field {
        &.unsubs,
        &.brand {
            li {
                list-style: disc;
                list-style-position: inside;
            }
        }

        &.history {
            margin-top: rem(40);
            padding-top: rem(30);
            border-top: rem(1) solid $gray-1;

            > .field-title {
                margin-bottom: rem(20);
                cursor: pointer;
            }

            &:not(.active) {
                .field-content {
                    display: none;
                }
            }
        }
    }

    .history-item {
        display: flex;
        margin-bottom: rem(20);
        padding: rem(10) rem(20);
        border: rem(1) solid $gray-1;
        border-radius: rem(10);
        background-color: $gray-0;
    }

    .plan-info,
    .price-info {
        flex: 1;
    }

    .price-info {
        margin-left: rem(24);
        padding-left: rem(24);
        border-left: rem(1) solid $gray-1;
    }
}
