@import "styles/utils";

.reviewer-score-record-ctrl {
    @include fieldset-style;
    display: flex;
    margin: rem(16) 0;

    > * {
        flex: 1;

        & + * {
            margin-left: rem(20);
        }
    }

    .field-title {
        width: rem(120);
    }

    .search-area {
        @include area-style;
    }

    .reason-input-wrapper {
        @include input-wrapper-style;
    }

    .btn-area {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex: 1;
    }

    .add-score-record-area {
        @include area-style;
    }
}
