@import "styles/utils";

#enter-coupon-info {
    .field-items {
        display: flex;
        align-items: center;

        .field {
            flex: 1;

            & + .field {
                margin-left: rem(36);
            }
        }
    }

    label {
        display: inline-flex;
        align-items: center;

        & + label {
            margin-left: rem(20);
        }

        input[type="radio"] {
            margin-left: 0;
        }
    }

    .with-space {
        margin: 0 rem(12);
    }

    .MuiInput-root {
        flex: 1;
        width: 100%;
        margin-right: rem(12);
    }
}
