.flex {
    display: flex;
}

.justify-center {
    justify-content: center;
}

.align-center {
    align-items: center;
}

.blue-font {
    color: $main-color !important;
}

.red-font {
    color: $red !important;
}

.medium-font {
    font-weight: $font-weight-medium !important;
}

.bold-font {
    font-weight: $font-weight-bold !important;
}

.error-message {
    margin-top: rem(4);
    font-size: rem(12);
    line-height: 1.4 !important;
    color: $red;
}

.main-title {
    margin-top: 72px;
    font-size: 36px;
    font-weight: $font-weight-normal;

    @include media("<mobile") {
        margin-top: mobile(50);
        margin-bottom: mobile(60);
        font-size: mobile(44);
    }
}

.deleted-item {
    td {
        color: $gray-1 !important;
    }
}

.row-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.overflow-ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.common-modal-style {
    @include modal-style;
}

.point-box {
    @include point-box-style;
}

input {
    height: 36px;
}

@include media(">=mobile") {
    .mobile {
        display: none !important;
    }
}

@include media("<mobile") {
    .web {
        display: none !important;
    }
}
