@import "styles/utils";

.project-request-search {
    @include filter-style;

    > div {
        display: flex;
        align-items: center;

        h3 {
            font-size: rem(16);
            font-weight: $font-weight-medium;
            min-width: rem(130);
            line-height: 1.5;
        }
    }

    .search-items {
        @include search-items-style;
    }

    .search-item {
        @include search-item-style(4);
    }

    .status-list {
        display: flex;

        li {
            display: flex;
            align-items: center;

            & + li {
                margin-left: rem(20);
            }

            label {
                margin-left: rem(5);
            }
        }
    }

    .btn-area {
        @include search-btn-style;
    }
}
