@import "styles/utils";

#talent-detail-modal {
    @include modal-style;
    @include fieldset-style;

    .section {
        padding: 0 0 rem(30);
        border-bottom: rem(1) solid $gray-1;

        &-title {
            margin-bottom: rem(20);
            font-size: rem(18);
            font-weight: $font-weight-medium;
        }

        & + .section {
            margin-top: rem(30);
        }
    }

    .field {
        &.image {
            .field-content {
                display: flex;
                flex-wrap: wrap;
                max-width: rem(900);
                margin: rem(-10);
            }

            .common-upload-image-item {
                margin: rem(10);
            }
        }
    }

    .field-sub-title {
        margin-bottom: rem(8);
    }

    .rs-react-quill-editor {
        & + .field-sub-title {
            margin-top: rem(20);
        }
    }

    textarea {
        width: 100%;
        height: rem(250);
    }
}
