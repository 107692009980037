@import "styles/utils";

.side-menu {
    background-color: $main-color;
    width: rem(210);
    min-width: rem(210);
    min-height: calc(100vh - 79px);

    .main-menu-list {
        color: $white;

        &.active {
            h3 {
                font-weight: $font-weight-medium;
                opacity: 1;
            }
        }

        .list-item {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: rem(14) rem(30);
            border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);

            i {
                font-size: rem(10);
                font-weight: $font-weight-bold;
            }
        }

        h3 {
            font-size: rem(14);
            font-weight: $font-weight-normal;
            line-height: 1.5;
            opacity: 0.8;

            &:hover {
                opacity: 1;
            }
        }

        ul {
            background-color: $active-color;
            padding: rem(13) 0;
        }

        .sub-list-item {
            cursor: pointer;
            padding: rem(7) 0 rem(7) rem(30);
            font-size: rem(13);
            font-weight: $font-weight-normal;
            line-height: 1.43;
            color: rgba($color: $white, $alpha: 0.9);

            &.active {
                font-weight: 600;
            }

            &:hover {
                font-weight: 600;
            }
        }

        .project {
            li:last-child {
                position: relative;
                padding-top: rem(20);
                margin-top: rem(13);

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: rem(30);
                    display: inline-block;
                    background-color: rgba($color: $white, $alpha: 0.2);
                    width: rem(150);
                    height: rem(1);
                }
            }
        }
    }
}
