@import "styles/utils";

.tagItem {
    display: flex;
    align-items: center;
    padding: rem(12) rem(8) rem(12) rem(12);
    font-size: rem(14);
    color: $gray-3;

    &.active {
        font-weight: $font-weight-medium;
        background-color: $blue-lighter;
        color: $main-color;
    }
}

.publicIcon {
    display: inline-flex;
    transform: scale(0.7);
}

.publicCheckbox {
    width: $field-height-s;
    height: $field-height-s;

    &:not(.active) {
        display: none;
    }
}

.tagSelectArea {
    display: flex;
    align-items: center;
    flex: 1;
    cursor: pointer;

    input[type="text"] {
        margin: 0 rem(6);
    }
}

button.editBtn,
button.removeBtn {
    @include btn-clear-style;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $field-height-s;
    height: $field-height-s;
    border-radius: 50%;
    font-size: rem(12);
    color: $gray-3;

    svg {
        width: rem(16);
        height: rem(16);
    }

    &:hover {
        background-color: $gray-1;
    }
}
