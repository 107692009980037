@import "styles/utils";

.project-modal-detail-info {
    @include fieldset-style;

    .field {
        &.row {
            align-items: center;
        }

        h3 {
            margin-top: 0;
        }
    }

    .project-ctrl-btn-area {
        button {
            width: rem(100);
            margin-bottom: rem(10);

            &:last-child {
                margin-bottom: none;
            }
        }
    }

    .info-title {
        font-size: rem(18);
        font-weight: $font-weight-medium;
        margin-top: rem(20);
        margin-bottom: rem(10);
    }

    textarea {
        height: rem(100);
        margin-top: rem(10);
    }

    .re-guideline {
        @include editor-text-style("m");
        overflow-y: auto;
        height: rem(410);
        padding: rem(20) rem(10);
        border: rem(1) solid $gray-1;
    }

    .date-field-area {
        h4 {
            font-size: rem(14);
            font-weight: $font-weight-normal;
            color: $gray-3;
        }

        .date-picker {
            display: flex;
            align-items: center;
            margin-top: rem(10);

            > p {
                margin: 0 rem(10);
            }
        }
    }

    .project-title {
        input {
            width: rem(770);
            max-width: 100%;
            font-size: rem(16);
            padding: rem(5);
        }
    }

    .project-item,
    .project-points {
        input[type="text"] {
            width: rem(300);
            max-width: 100%;
            font-size: rem(16);
            padding: rem(5);
        }
    }

    .project-item {
        &.changed {
            // background-color: $green-lighter;
        }
    }

    .project-type,
    .project-category,
    .project-plan,
    .project-sns {
        ul {
            display: flex;

            li {
                margin-right: rem(20);

                label {
                    display: flex;
                    align-items: center;
                }
            }

            input {
                margin-right: rem(10);
            }
        }
    }

    .image-item {
        .info-title {
            margin-top: 0;
        }

        .main-image,
        .sub-image {
            width: rem(200);
            max-width: 100%;
            // height: rem(200);
            border: 1px dashed $gray-3;
            position: relative;
            &:before {
                content: "";
                display: block;
                padding-bottom: 100%;
            }
        }

        img {
            width: auto;
            max-width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: contain;
        }

        .remove-icon {
            position: absolute;
            top: rem(-5);
            right: rem(-5);
            z-index: 100;
        }

        .add-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
        }
    }

    .project-options {
        h4 {
            font-size: rem(14);
            font-weight: $font-weight-normal;
        }

        input {
            margin-top: rem(10);
            width: 100% !important;
        }
    }

    .project-address-info {
        h4 {
            font-size: rem(16);
            font-weight: $font-weight-normal;
        }

        input {
            width: 80%;
            font-size: rem(16);
            padding: rem(5) rem(10);
        }

        .search-address-area {
            display: flex;
            align-items: center;

            input {
                width: 40%;
                margin-right: rem(20);
            }

            .address {
                margin-right: rem(10);
                padding: rem(10);
                border: 1px solid $gray-1;
                height: rem(38);
                min-width: rem(100);
            }
        }
    }

    .proejct-check-point {
        h4 {
            font-size: rem(16);
            font-weight: $font-weight-normal;
            color: $gray-3;
        }
    }

    .project-onwer-info {
        li {
            display: flex;
            align-items: center;
            margin-bottom: rem(10);

            &:last-child {
                margin-bottom: none;
            }

            h4 {
                font-size: rem(16);
                font-weight: $font-weight-normal;
                width: rem(150);
            }

            input {
                width: rem(450);
                font-size: rem(16);
                padding: rem(5);
            }

            #phonenumber2 {
                margin-right: rem(10);
                font-size: rem(14);
            }
        }
    }

    .item-option {
        display: flex;
        width: 100%;

        > div:first-child {
            margin-right: rem(20);
        }
    }

    .option-value-list {
        display: flex;
        flex-wrap: wrap;
        margin: rem(6) rem(-4) rem(10);
    }

    .option-value-item {
        min-width: auto;
        margin: rem(4);
        padding: rem(2) rem(10);
        text-transform: inherit;
        background-color: $gray-1;

        .rs-icon {
            margin-left: rem(4);
            font-size: rem(10);
            cursor: pointer;
        }
    }
}
