@import "styles/utils";

.reviewer-detail-modal-wrapper {
    @include modal-style;

    .isPast:not(.deleted-item) {
        td {
            color: $gray-2 !important;
        }
    }
}
