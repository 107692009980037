@import "styles/utils";

.subs-statistics-graph-filter {
    margin-top: rem(40);
    display: flex;
    justify-content: space-between;

    .statistics-filter-list {
        display: flex;

        button {
            padding-right: rem(10);
            padding-left: rem(10);
        }

        li + li {
            margin-left: rem(15);
        }
    }

    .month-filter {
        width: rem(100);
    }
}
