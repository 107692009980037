@import "styles/utils";

.common-filter-items {
    @include filter-style;

    fieldset {
        @include search-items-style;
    }

    .field {
        @include search-item-style(5);
    }

    .button-box {
        @include search-btn-style;
    }
}
