@import "styles/utils";

.message-item-alarm-content {
    width: rem(290);
    padding: 0;

    @include media("<mobile") {
        width: mobile(430);
    }

    .top-illust-image {
        height: rem(180);
        background-color: $gray-1;
        background-size: cover;

        @include media("<mobile") {
            height: mobile(243);
        }
    }

    .alarm-detail {
        padding: rem(20) rem(14) rem(14);

        @include media("<mobile") {
            padding: mobile(32) mobile(24);
        }
    }

    .alarm-title {
        margin-bottom: rem(14);
        padding-bottom: rem(14);
        border-bottom: rem(1) solid $gray-1;

        @include media("<mobile") {
            margin-bottom: mobile(24);
            padding-bottom: mobile(32);
            border-bottom-width: mobile(1);
        }
    }

    .title-text {
        margin-bottom: rem(2);
        font-size: rem(14);
        font-weight: $font-weight-medium;
        color: $gray-5;

        @include media("<mobile") {
            margin-bottom: mobile(4);
            font-size: mobile(28);
        }
    }

    .sub-text {
        font-size: rem(20);
        font-weight: $font-weight-bold;
        color: $gray-5;

        @include media("<mobile") {
            font-size: mobile(36);
        }
    }

    .alarm-text {
        margin-bottom: rem(10);
        font-size: rem(14);
        line-height: 1.6;
        color: $gray-3;

        @include media("<mobile") {
            margin-bottom: mobile(16);
            font-size: mobile(24);
        }
    }

    .detail-list {
        margin-bottom: rem(14);
        padding-bottom: rem(14);
        border-bottom: rem(1) solid $gray-1;

        @include media("<mobile") {
            margin-bottom: mobile(24);
            padding-bottom: mobile(24);
            border-bottom-width: mobile(1);
        }
    }

    .detail-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: rem(12);

        @include media("<mobile") {
            display: block;
            font-size: mobile(24);
        }

        & + .detail-item {
            margin-top: rem(6);

            @include media("<mobile") {
                margin-top: mobile(20);
            }
        }

        p {
            width: rem(94);
            margin-right: rem(6);
            white-space: pre-wrap;
            color: $gray-3;

            @include media("<mobile") {
                width: 100%;
                margin-right: 0;
                margin-bottom: mobile(4);
                white-space: normal;
            }
        }

        div {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            overflow: hidden;
            text-align: right;
            color: $gray-5;

            @include media("<mobile") {
                display: block;
                text-align: left;
            }
        }
    }

    .ellipsis-text {
        @include media(">=mobile") {
            @include ellipsis;
            flex: 1;
        }
    }

    .alarm-buttons {
        display: flex;
        flex-wrap: wrap;
        margin-top: rem(14);

        @include media("<mobile") {
            margin-top: mobile(24);
        }

        .rs-button {
            flex: 1;
        }
    }

    .rs-caution-area {
        color: $gray-2;
    }
}
