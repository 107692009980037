@import "styles/utils";

.change-brand-modal-contents {
    @include fieldset-style;
    @include modal-style;

    width: rem(600);
    height: rem(600);
    padding: rem(30);

    .MuiInput-underline {
        flex: 1;
        width: 100%;
    }

    .change-item {
        &:not(.active) {
            label + * {
                display: none;
            }
        }

        & + .change-item {
            margin-top: rem(20);
            padding-top: rem(20);
            border-top: rem(1) solid $gray-1;
        }
    }

    label {
        display: flex;
        align-items: center;
        font-size: rem(18);
        font-weight: $font-weight-medium;
        cursor: pointer;

        input {
            margin-right: rem(12);
        }

        & + div {
            margin-top: rem(12);
        }
    }

    .field-title {
        width: rem(80);
    }

    .btn-area {
        display: flex;
        justify-content: flex-end;
        margin-top: rem(20);
    }
}
