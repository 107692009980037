@import "styles/utils";

#payment-list {
    .total-points {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: rem(20);
        padding: rem(12) rem(20);
        border: rem(1) solid $gray-1;
        font-weight: $font-weight-medium;
        background-color: $white;

        p {
            font-size: rem(18);
        }

        span {
            font-size: rem(22);
        }
    }
    .history {
        &-list {
            background-color: $white;

            table {
                width: 100%;
                border: rem(1) solid $gray-1;
                border-radius: rem(6);
                border-collapse: collapse;
                font-size: rem(14);
            }

            thead {
                background-color: $gray-0;
                color: $gray-3;

                th {
                    padding: rem(11) rem(12);
                    font-size: rem(14);
                    white-space: pre;
                }
            }

            tbody {
                th {
                    height: rem(56);
                    padding: 0 rem(12);

                    &.project-amount,
                    &.project-point {
                        text-align: right;
                    }

                    &.project-title {
                        text-align: left;
                    }

                    @include media(">=mobile") {
                        > p {
                            display: none;
                        }
                    }
                }

                .event-text {
                    font-size: rem(12);
                    line-height: 1.7;
                    color: $gray-2;
                }
            }

            th {
                vertical-align: middle;
                border: rem(1) solid $gray-1;
                font-weight: $font-weight-normal;

                .rs-icon {
                    margin-left: rem(4);
                    font-size: rem(8);
                }

                &.project-date {
                    @include media(">=mobile") {
                        width: rem(105);
                    }
                }

                &.project-id {
                    @include media(">=mobile") {
                        width: rem(116);
                    }
                }

                &.project-status {
                    @include media(">=mobile") {
                        width: rem(130);
                    }
                }

                &.project-title {
                    @include media(">=mobile") {
                        @include ellipsis;
                    }
                }

                &.project-category {
                    @include media(">=mobile") {
                        width: rem(130);
                    }
                }

                &.project-amount {
                    @include media(">=mobile") {
                        width: rem(127);
                    }
                }

                &.project-point {
                    @include media(">=mobile") {
                        width: rem(125);
                    }
                }

                &.project-receipt {
                    @include media(">=mobile") {
                        width: rem(190);
                        white-space: pre-line;
                    }
                }

                &.project-date-id {
                    @include media("<mobile") {
                        justify-content: flex-start;
                        align-items: center;
                        color: $gray-2;

                        span {
                            display: flex;
                            align-items: center;
                        }

                        span:last-child {
                            &:before {
                                content: "";
                                display: block;
                                width: mobile(1);
                                height: mobile(20);
                                margin: 0 mobile(8);
                                background-color: $gray-1;
                            }
                        }
                    }
                }
            }
        }
    }

    .non-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: rem(200) 0 rem(283);
        color: $gray-2;

        .rs-icon {
            font-size: rem(45);
        }

        img {
            margin-top: rem(16);
            height: rem(16);
        }
    }
}
