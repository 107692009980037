@import "styles/utils";

#suggestion-detail-modal {
    @include modal-style;
    @include fieldset-style();

    h2 {
        margin-right: rem(12);
    }

    .refund-status {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1;
        font-weight: $font-weight-medium;

        .rs-icon {
            margin-right: rem(8);
            font-size: rem(20);
            color: $main-color;
        }
    }

    .section {
        padding: 0 0 rem(30);
        border-bottom: rem(1) solid $gray-1;

        &-title {
            margin-bottom: rem(20);
            font-size: rem(18);
            font-weight: $font-weight-medium;
        }

        & + .section {
            margin-top: rem(30);
        }
    }

    .field {
        &.reason {
            ul {
                list-style: disc;
                padding-left: rem(20);
            }

            .field-content {
                margin-top: rem(-10);

                p {
                    margin-top: rem(10);
                }

                div {
                    margin-top: rem(10);
                    padding: rem(16);
                    background-color: $gray-0;
                    white-space: pre-line;
                }
            }
        }
    }

    textarea {
        width: 100%;
        height: rem(250);
    }

    .rs-suggest-status {
        margin-left: rem(12);
    }
}
