@import "styles/utils";

#order-detail-modal {
    @include modal-style;
    @include fieldset-style();

    .section {
        padding: 0 0 rem(30);
        border-bottom: rem(1) solid $gray-1;

        &-title {
            margin-bottom: rem(20);
            font-size: rem(18);
            font-weight: $font-weight-medium;
        }

        & + .section {
            margin-top: rem(30);
        }
    }

    .field {
        &.result {
            li {
                list-style: decimal;
                list-style-position: inside;
            }
        }
    }

    textarea {
        width: 100%;
        height: rem(200);
    }
}
