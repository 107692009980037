@import "styles/utils";

#create-banner-modal {
    @include modal-style;
    @include fieldset-style;

    .field-content {
        display: flex;
    }

    .input-items {
        display: flex;
        flex-wrap: wrap;
        margin: rem(12) rem(-12);

        > * {
            width: calc(50% - #{rem(24)});
            margin: rem(12);
        }
    }

    .upload-image-item {
        & + .upload-image-item {
            margin-left: rem(20);
        }

        p {
            margin-bottom: rem(12);
            font-size: rem(14);
            color: $gray-2;
        }
    }

    .upload-image-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(300);
        height: rem(180);
        border: rem(1) dashed $border-color;
        border-radius: rem(10);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;

        &:hover {
            .rs-icon {
                opacity: 1;
            }
        }

        .rs-icon {
            font-size: rem(30);
            opacity: 0.3;
        }
    }

    .checkbox-item {
        display: flex;
        align-items: center;

        & + .checkbox-item {
            margin-left: rem(10);
        }
    }

    .MuiFormControlLabel-root {
        margin-left: 0;
    }
}
