@import "styles/utils";

.reviewerVerified-search-area {
    @include filter-style;

    .row-item {
        margin-bottom: rem(16);

        h2 {
            margin-right: rem(20);
            min-width: rem(100);
        }
    }

    .search-items {
        @include search-items-style;
    }

    .search-item {
        @include search-item-style(4);
    }

    label {
        display: flex;
        align-items: center;

        input {
            margin-right: rem(8);
        }
    }

    ul {
        display: flex;
        flex-direction: row;
    }

    .btn-area {
        @include search-btn-style;
    }
}
