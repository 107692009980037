@import "styles/utils";

#talent-list-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(16) rem(20);

    .count-list,
    .btn-list {
        display: flex;

        li {
            & + li {
                margin-left: rem(8);
            }
        }
    }

    .count-list {
        li {
            & + li {
                padding-left: rem(8);
                border-left: rem(1) solid $gray-2;
            }
        }
    }

    .btn-list {
    }
}
