@import "styles/utils";

.wrapper {
    display: flex;
    height: rem(600);
}

.tagList {
    display: flex;
    flex-direction: column;
    flex: 1;

    & :global {
        .tag-list {
            overflow: hidden;
            flex: 1;
            background-color: $white;

            ul {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
            }

            li {
                width: 50%;
            }
        }
    }
}

.userList {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: rem(20);
    background-color: $white;

    .scrollArea {
        overflow-y: auto;
        flex: 1;
    }

    .userItem {
        list-style: disc;
        list-style-position: inside;
        padding: rem(12);
        cursor: pointer;
    }
}

.userListTitle {
    flex-shrink: 0;
    padding: rem(20) rem(12);
    font-weight: $font-weight-medium;
    border-bottom: rem(1) solid $gray-1;
}
