@import "styles/utils";

.template-search-area {
    @include filter-style;

    .search-items {
        @include search-items-style;
    }

    .search-item {
        @include search-item-style(3);
    }

    .btn-area {
        @include search-btn-style;
    }
}
