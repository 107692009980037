@import "styles/utils";

.user-manage-edit-user-info {
    @include fieldset-style;

    .field-title {
        width: rem(140);
    }

    .label-list {
        @include label-btn-list-style;
    }

    .field {
        &.address {
            .field-content {
                @include input-wrapper-style;

                p {
                    margin-right: rem(12);
                }
            }
        }
    }

    .list {
        list-style: disc;
        list-style-position: inside;
    }

    .sns-list {
        > li {
            display: flex;
            align-items: center;

            p {
                width: rem(170);
            }
        }

        > li + li {
            margin-top: rem(10);
        }
    }

    a {
        @include link-style;
    }
}

.user-manage-edit-actions {
    flex-wrap: wrap;

    .btn-area {
        display: flex;
        justify-content: center;
        width: 100%;

        & + .rs-info-box {
            margin-top: rem(10);
        }
    }
}
