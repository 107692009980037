@import "styles/utils";

.user-manage-limit-function {
    @include fieldset-style;

    .field {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        > * {
            flex-shrink: 0;
        }
    }

    .limit-status {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .select-box {
        flex: 1;
        padding-left: rem(10);
    }

    .options {
        flex: 1;
        width: 100%;
        border-bottom: rem(1) solid $gray-1;
        font-size: rem(14);

        &.disabled {
            color: $gray-2;
        }

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            height: rem(42);
        }

        p {
            display: flex;
            align-items: center;
            margin-left: rem(10);

            &:before {
                content: "";
                display: block;
                width: rem(7);
                height: rem(7);
                margin-right: rem(5);
                border-left: rem(1) solid $gray-2;
                border-bottom: rem(1) solid $gray-2;
            }
        }

        .MuiInputBase-root {
            font-size: inherit;
        }

        & + button {
            width: rem(80);
            margin-top: rem(20);
        }
    }
}
