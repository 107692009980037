@import "styles/utils";

.project-search {
    @include filter-style;

    .search-category {
        display: flex;
        align-items: flex-start;
        margin-bottom: rem(16);

        h3 {
            margin-top: rem(8);
            font-size: rem(16);
            font-weight: $font-weight-medium;
            min-width: rem(100);
            line-height: 1.5;
        }

        label {
            display: flex;
            align-items: center;

            input {
                margin-right: rem(8);
            }
        }
    }

    .search-items {
        @include search-items-style;
    }

    .search-item {
        @include search-item-style(5);
    }

    .search-date {
        display: flex;

        ul {
            display: flex;

            li {
                margin-right: rem(40);
            }

            input {
                margin-right: rem(10);
            }
        }

        .date-picker {
            display: flex;
            align-items: center;

            p {
                margin: 0 rem(15);
            }
        }
    }

    .search-field {
        display: flex;
        flex-wrap: wrap;
        flex: 1;

        li {
            display: flex;
            align-items: center;
            width: 40%;
            margin-bottom: rem(20);

            &:last-child {
                margin-bottom: 0;
            }

            h4 {
                font-size: rem(14);
                font-weight: $font-weight-normal;
                width: rem(120);
            }
        }
    }

    .search-options {
        li {
            margin-bottom: rem(10);

            &:last-child {
                margin-bottom: none;
            }
        }
    }

    .search-btn-area {
        @include search-btn-style;
    }
}
