@import "styles/utils";

.send-marketing-email {
    padding: 20px;
    background-color: $white;

    .row-item {
        margin-top: 10px;
        display: flex;
        align-items: center;

        input[type="text"] {
            width: 500px;
        }

        .title-label {
            font-weight: bold;
        }

        .title-label,
        h2 {
            width: 120px;
        }

        label {
            display: flex;
            align-items: center;
        }

        label + label {
            margin-left: 10px;
        }
    }

    .btn-wrapper {
        margin-top: 40px;
        display: flex;
        justify-content: center;
    }
}

.check-sending-email-modal {
    @include modal-style;
    width: 400px;
    height: 400px;

    h2 {
        @include modal-title-style;
    }

    ul {
        @include modal-content-style;
    }

    li {
        display: flex;
        align-items: center;
    }

    li + li {
        margin-top: 10px;
    }

    h3 {
        width: 110px;
    }
}
