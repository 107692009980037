@import "styles/utils";

.business-center-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: $white;
    padding: rem(27) rem(30);
    border-bottom: 1px solid $gray-1;

    .logo-area {
        display: flex;
        align-items: center;

        .reviewshare-logo {
            width: rem(190);
            height: rem(24);
            margin-right: rem(8);
        }

        .admin-title {
            h2 {
                font-size: rem(20);
                font-weight: $font-weight-bold;
            }
        }
    }

    .header-menu {
        display: flex;
        align-items: center;
        font-weight: $font-weight-medium;
        color: $gray-3;
    }

    .business-name {
        font-size: rem(14);
        line-height: 1.43;
    }

    // .move-manage-my-info-btn {
    //     margin-left: rem(10);
    //     padding: rem(2) rem(10);
    //     border-radius: rem(12);
    //     border: 1px solid $gray-1;
    //     line-height: 1.5;
    //     font-size: rem(12);
    // }

    .logout-btn {
        margin-left: rem(4);
        padding-left: rem(8);
        padding-right: rem(8);
        border-radius: rem(60);
    }

    .connect-insta-btn {
        margin-left: rem(12);
        border-radius: rem(60);
    }
}
