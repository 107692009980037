@import "styles/utils";

#message-box-list {
    overflow-x: hidden;
    overflow-y: auto;
    width: rem(300);
    height: rem(600);
    border: rem(1) solid $gray-1;
    background-color: $white;

    li {
        padding: rem(20) rem(16);
        border-bottom: rem(1) solid $gray-1;
        cursor: pointer;

        &.active {
            background-color: $blue-lighter;
        }

        p {
            line-height: 1.5;
        }
    }

    .more-btn {
        margin: rem(20) auto;
        padding-left: rem(10);
        padding-right: rem(10);
    }
}
