@import "styles/utils";

.project-detail-modal {
    @include modal-style;

    &.has-request-modify {
        width: 90%;

        .prev-info,
        .next-info {
            width: 50%;
        }

        .prev-info {
            padding-right: rem(25);

            .changed {
                background-color: $red-lighter;
            }
        }

        .next-info {
            padding-left: rem(25);
            border-left: rem(1) solid $gray-1;

            .changed {
                background-color: $green-lighter;
            }
        }

        #rs-react-quill-toolbar {
            display: none;
        }
    }

    .modal-content {
        &.disabled {
            // pointer-events: none;
        }
    }

    .modal-content-wrapper {
        display: flex;
    }

    .modal-footer {
        flex-wrap: wrap;

        .reject-modify-reason {
            width: 100%;
        }

        textarea {
            height: rem(80);
            margin-bottom: rem(16);
            border-color: $red !important;
        }
    }

    .tag-items {
        display: flex;
        align-items: center;

        .rs-tag {
            & + .rs-tag {
                margin-left: rem(6);
            }
        }
    }
}
