@import "styles/utils";

.business-page-template {
    .page-body {
        display: flex;
        flex-direction: row;
    }

    .main-contents {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-x: hidden;
    }

    .main-contents-body {
        flex: 1;
        background-color: $gray-0;
        padding: rem(20);
    }
}
