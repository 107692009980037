@import "styles/utils";

form.post-editor {
    & + .field {
        @include field-style;
        margin-top: rem(20);
    }

    .column-item {
        display: flex;
        flex-direction: column;

        &.row {
            flex-direction: row;
            align-items: center;

            .item-title {
                margin-right: rem(10);
                margin-bottom: 0;
            }
        }

        .item-title {
            margin-bottom: rem(10);
        }

        #notice-title {
            padding: rem(5);
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            margin: rem(-6);
        }

        li {
            display: flex;
            align-items: center;
            margin: rem(6);
        }

        input[type="radio"] {
            height: auto;
        }
    }

    .column-item + .column-item {
        margin-top: rem(20);
    }

    .editor-area {
        margin-top: rem(20);
    }

    .btn-area {
        margin-top: rem(20);
        display: flex;
        justify-content: center;
    }
}
