@import "styles/utils";

.reviewer-point-request-ctrl {
    @include fieldset-style;
    display: flex;
    margin: rem(16) 0;

    > * {
        flex: 1;

        & + * {
            margin-left: rem(20);
        }
    }

    .field-title {
        width: rem(120);
    }

    .search-area {
        @include area-style;
    }

    .type-input-wrapper {
        @include input-wrapper-style;
    }

    .status-list {
        @include label-btn-list-style;
    }

    .btn-area {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex: 1;
    }

    .set-points-area {
        @include area-style;
    }
}
