@import "styles/utils";

.feedback-detail-modal {
    @include modal-style;

    .modal-content {
        h3 {
            margin: rem(10) 0;
        }
    }

    .contents {
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    .btn-area {
        display: flex;
        justify-content: center;
        padding-bottom: rem(30);
    }

    .cancel-reported-btn {
        width: rem(200);
    }
}
