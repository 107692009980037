body {
    margin: 0;
    box-sizing: border-box;
    word-break: break-all;
    color: $text-color;
}

a {
    text-decoration: none;
    color: inherit;
}

* {
    box-sizing: border-box;
    font-family: "Noto Sans Korean", sans-serif;

    @include media(">=mobile") {
        &::-webkit-scrollbar {
            width: rem(6);
            height: rem(6);
        }

        &::-webkit-scrollbar-thumb {
            background-color: $gray-2;
            border-radius: rem(10);
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
    }
}

*:focus {
    outline: none;
}

html,
body {
    // height: 100%;
    font-size: $font-size-m;

    @include media("<web") {
        font-size: 12px;
    }
}

#modal {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9000;
}

#root {
    // height: 100%;
    display: flex;
    flex-direction: column;
}

ul,
ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
p {
    margin: 0;
    font-size: inherit;
    line-height: 1;
}

button {
    cursor: pointer;
}

fieldset {
    margin: 0;
    padding: 0;
    border: none;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="password"],
textarea {
    // -webkit-appearance: none;
    border: rem(1) solid $border-color;

    &::placeholder {
        line-height: normal;
    }
    &::-webkit-input-placeholder {
        line-height: normal;
    }
    &::-moz-placeholder {
        line-height: normal;
    }

    &::-ms-input-placeholder {
        color: $gray-2;
    }
    &::-webkit-input-placeholder {
        color: $gray-2;
    }
    &::-moz-input-placeholder {
        color: $gray-2;
    }

    &:disabled {
        color: $gray-2;
        opacity: 1;
        -webkit-text-fill-color: inherit;
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px white inset;
    }

    &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 50px white inset;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="password"] {
    @include input-style();
}

textarea {
    display: block;
    width: 100%;
    padding: rem(8) rem(15);
    border: rem(1) solid $gray-1;
    border-radius: rem(4);
    font-size: rem(16);
    line-height: 1.5;

    resize: none;
    box-sizing: border-box;
    outline: 0;
    box-shadow: none;
    appearance: none;
    -webkit-appearance: none;

    &:not(:disabled):focus {
        border-color: $gray-2;
    }
}

.react-date-picker > .react-date-picker__wrapper {
    display: none;
}

.MuiCheckbox-root {
    padding: 0 !important;
}

.MuiTableCell-root {
    overflow: hidden;
    max-width: rem(280);
    white-space: nowrap !important;
    @include ellipsis;

    p {
        @include ellipsis;
    }

    a {
        @include link-style;
        @include ellipsis;
    }

    &,
    &.MuiTableCell-sizeSmall {
        height: rem(50);
        padding: rem(4) rem(15) !important;
    }
}

.MuiTableBody-root {
    .MuiTableCell-root.open-detail {
        cursor: pointer;

        &:hover {
            background-color: $gray-1;
        }

        .icon_project {
            margin-right: rem(12);
        }
    }
}

.MuiTableRow-root {
    input[type="checkbox"] {
        width: rem(20);
        height: rem(20);
    }
}

.rs-react-quill-editor {
    .ql-container {
        @include media(">=mobile") {
            font-size: rem(16) !important;

            h3 {
                font-size: rem(18) !important;
            }
        }
    }
}

.ReactCrop {
    > div {
        width: 100%;
        height: 100%;
    }

    .ReactCrop__image {
        max-height: 100%;
    }
}

.icon_deposit {
    color: $main-color;
}
