@import "styles/utils";

#point-gift-list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-color: $white;
    border: rem(1) solid $gray-1;
    border-radius: rem(6);

    > div {
        width: 100%;
    }

    .button-box {
        display: flex;
        justify-content: space-between;
        padding: rem(16) rem(20);
    }

    .banner-list-row {
        cursor: pointer;
    }
}
