@import "styles/utils";

.wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: $gray-0;
}

.title {
    flex-shrink: 0;
    padding: rem(20) rem(12);
    font-weight: $font-weight-medium;
    border-bottom: rem(1) solid $gray-1;
}

.tagItems {
    flex: 1;
    overflow-y: auto;
}

.spin {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.btnSpin {
    width: rem(20) !important;
    height: rem(20) !important;
}

.searchArea,
.addArea {
    display: flex;
    align-items: center;
    padding-top: rem(12);
    padding-left: rem(12);
    padding-right: rem(12);

    input[type="text"] {
        flex: 1;
    }
}

.searchArea {
    padding-bottom: rem(12);
    color: inherit;
}

.addArea {
    &:not(.active) {
        > *:not(.addBtn) {
            display: none;
        }

        .addBtn {
            width: 100%;
            margin-left: 0;
        }
    }

    input[type="text"] {
        margin-left: rem(6);
    }
}

button.addBtn,
button.cancelBtn,
button.searchBtn {
    width: rem(60);
    height: $field-height-m;
    margin-left: rem(6);

    & :global {
        .rs-icon {
            font-size: rem(18);
        }
    }
}

.publicIcon {
    display: inline-flex;
    transform: scale(0.7);
}

button.confirmBtn {
    // align-self: center;
    width: rem(50);
    min-width: auto;
    // height: rem(50);
    margin-right: rem(30);
    border-left: rem(1) solid $gray-1;
    border-radius: 0;
    background-color: $gray-0;

    &:hover {
        border-color: $blue-lighter;
        background-color: $blue-lighter;
        color: $main-color;
    }

    & ~ * {
        order: 2;

        &.confirmBtn {
            order: 1;
            margin-left: rem(30);
            margin-right: 0;
            transform: rotate(180deg);
        }
    }
}
