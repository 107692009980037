@import "styles/utils";

#talent-list {
    background-color: $white;
    border: rem(1) solid $gray-1;

    .talent-list-row {
        cursor: pointer;
    }
}
