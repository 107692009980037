@import "styles/utils";

#create-talent-add-image {
    @include media("<mobile") {
        margin-top: mobile(32);
    }

    ul {
        display: flex;
        align-items: center;
        margin: rem(-5);

        @include media("<mobile") {
            flex-wrap: wrap;
            margin: mobile(-9);
        }
    }

    li {
        margin: rem(5);

        @include media("<mobile") {
            margin: mobile(9);
        }
    }
}
