@import "styles/utils";

.faq-search {
    @include filter-style;

    .search-items {
        @include search-items-style;
    }

    .search-item {
        @include search-item-style(5);
    }

    .btn-area {
        @include search-btn-style;
    }
}
