@import "styles/utils";

#messenger-filter {
    @include filter-style;

    fieldset {
        @include search-items-style;
    }

    .field {
        @include search-item-style(3);
    }

    input {
        &.isRequired {
            border-color: $red;
        }
    }

    .button-box {
        @include search-btn-style;
    }
}
