/*----------------------*/
/*----- Responsive -----*/
/*----------------------*/
@import "~include-media/dist/include-media";

// WIDTH
$width-s: 768px;
$width-m: 1024px;
$width-l: 1200px;
$width-xl: 1445px;

//BREAKPOINTS
$breakpoints: (
    mobile: $width-s,
    medium-web: $width-m,
    web: $width-l,
    wide-web: $width-xl
);

@function rem($px) {
    @return ($px * 1px/16px) * 1rem;
}

// tablet size
@function tablet($px) {
    // @return $px/320*100vw;
    // @return $px/720*100vw;
    @return $px/1024 * 100vw;
    // @return $px/1200*100vw;
}

// mobile size
@function mobile($px) {
    @if unitless($px) {
        @return $px/720 * 100vw;
    } @else {
        @return $px/1px/720 * 100vw;
    }
    // @return $px/320*100vw;
    // @return $px/720*100vw;
    // @return $px/1024*100vw;
    // @return $px/1200*100vw;
}

@function laptop($px) {
    //@return $px/1445*80vw;
    @return $px * 0.8px;
}
