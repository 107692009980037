@import "styles/utils";

#feed-pick-page {
    .feed-list {
        border: rem(1) solid $gray-1;
        background-color: $white;
    }

    .total-counts {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: rem(12) rem(16);
        border-bottom: rem(1) solid $gray-1;
    }

    .feed-items {
        display: flex;
        flex-wrap: wrap;
    }

    .feed-item {
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        width: 12.5%;
        min-width: 12.5%;
        max-width: 12.5%;
        padding: 0;
        border: rem(0.5) solid $gray-1;
    }

    .checkbox-icon {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        background-color: $white;
    }

    .feed-img {
        position: relative;
        width: 100%;

        &:before {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

        img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .feed-id {
        margin-top: rem(4);
    }
}
