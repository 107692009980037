@import "styles/utils";

#coupon-detail-modal {
    @include modal-style;
    @include fieldset-style;

    .etc-info {
        display: flex;
    }

    h3 {
        margin-bottom: rem(12);
        font-size: rem(18);
    }

    fieldset {
        & + fieldset {
            padding-top: rem(20);
            border-top: rem(1) solid $gray-1;
        }
    }

    .field {
        &.row {
            .field-content {
                text-align: right;
            }
        }

        &.biz {
            li {
                list-style-type: disc;
                list-style-position: inside;
            }
        }
    }
}
