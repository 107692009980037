@import "styles/utils";

.previous-contact-us-list-area {
    margin-top: rem(20);

    h3 {
        font-size: rem(16);
        margin-bottom: rem(10);
    }

    li {
        color: $blue-dark;
        text-decoration: underline;
    }
}
