@import "styles/utils";

.reply-area {
    margin-top: rem(20);

    .template-list {
        min-height: rem(100);
        max-height: rem(300);
        overflow-y: auto;
        background-color: $gray-1;
        margin: rem(10) 0;
        padding: rem(5) rem(20);

        li {
            cursor: pointer;
        }
    }

    .template-area {
        .search-area {
            display: flex;
            align-items: center;

            input {
                width: rem(200);
                margin-right: rem(10);
            }
        }
    }

    .reply-contents {
        background-color: $white;
        padding: rem(20);
        display: flex;
        flex-direction: column;

        .manager-area {
            h3 {
                font-size: rem(16);
                font-weight: 500;
                margin-right: rem(10);
            }

            > div {
                display: flex;

                label {
                    display: flex;
                    align-items: center;

                    input {
                        margin-right: rem(8);
                    }
                }

                label + label {
                    margin-left: rem(20);
                }
            }
        }

        .editor-area {
            margin-top: rem(20);
        }
    }

    .attachments-area {
        margin-top: rem(20);

        .attachment-list {
            li {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: $gray-0;
                max-width: rem(400);
                padding: rem(10);

                p {
                    max-width: rem(380);
                    @include ellipsis;
                }

                span {
                    margin-left: rem(10);
                    color: $gray-2;
                }
            }

            li + li {
                margin-top: rem(15);
            }
        }
    }

    .btn-area {
        margin-top: rem(20);
        display: flex;
        justify-content: center;
    }
}
