@import "styles/utils";

.project-modal-status-info {
    .rs-info-box {
        margin-bottom: rem(12);
        white-space: pre-wrap;

        p {
            margin-bottom: rem(12);
        }
    }

    .status-main-info {
        display: flex;
        flex: 1;
        @include fieldset-style;
    }
}
