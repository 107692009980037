@import "styles/utils";

#common-crop-image-modal {
    width: rem(342);

    @include media("<mobile") {
        width: 100%;
    }

    .title {
        margin-bottom: rem(16);
        font-size: rem(24);
        font-weight: $font-weight-bold;

        @include media("<mobile") {
            margin-bottom: mobile(28);
            font-size: mobile(36);
        }
    }

    #upload-hidden-image,
    .crop-image-area {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: rem(340);
        margin-top: rem(8);
        border: rem(1) solid $gray-1;
        border-radius: rem(6);

        @include media("<mobile") {
            height: mobile(574);
            margin-top: mobile(32);
            border-width: mobile(1);
            border-radius: mobile(10);
        }

        &:not(.max-height) {
            .ReactCrop {
                &__image {
                    width: 100%;
                }
            }
        }

        &.max-height {
            .ReactCrop {
                &__image {
                    max-height: rem(340);

                    @include media("<mobile") {
                        max-height: mobile(574);
                    }
                }
            }
        }
    }

    .button-wrapper {
        margin-top: rem(20);

        @include media("<mobile") {
            margin-top: mobile(36);
        }

        button {
            flex: 1;

            & + button {
                margin-left: rem(10);

                @include media("<mobile") {
                    margin-left: mobile(10);
                }
            }
        }
    }
}
