@import "styles/utils";

.common-upload-image-item {
    position: relative;
    overflow: hidden;
    width: rem(180);
    border: rem(1) dashed $gray-1;
    border-radius: rem(10);
    background-size: cover;

    @include media("<mobile") {
        width: mobile(206);
        border-width: mobile(1);
        border-radius: mobile(20);
    }

    &:not(.empty) {
        border: none;
    }

    &:before {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        object-fit: cover;
    }

    .button-items {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .edit-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($gray-5, 0.7);

        .line {
            display: block;
            width: rem(1);
            height: rem(10);
            background-color: $gray-2;

            @include media("<mobile") {
                width: mobile(2);
                height: mobile(20);
            }
        }
    }

    .add-btn {
        width: $field-height-m;

        @include media("<mobile") {
            width: $field-height-m-xs;
        }
    }

    .edit-btn,
    .remove-btn {
        @include btn-clear-style;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: $field-height-m;
        font-size: $field-font-size-m;
        color: $white;
        cursor: pointer;

        @include media("<mobile") {
            height: $field-height-m-xs;
            font-size: $field-font-size-m-xs;
        }
    }
}
