@mixin modal-title-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: rem(20);
    // padding: rem(16);
    // border: rem(2) solid $gray-2;
    // border-radius: rem(10);
    order: -1;
    padding: rem(20) $modal-indent;
    border-bottom: rem(1) solid $gray-1;
    box-shadow: rem(1) 0 rem(6) 0 rgba($black, 0.04);
    font-size: rem(22);
    // white-space: pre-wrap;
}

@mixin modal-content-style {
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    padding: $modal-top-padding $modal-indent;
}

@mixin modal-footer-style {
    display: flex;
    justify-content: center;
    padding: rem(24) $modal-indent;
    border-top: rem(1) solid $gray-1;

    button {
        min-width: rem(100);

        & + button {
            margin-left: rem(12);
        }
    }
}

@mixin modal-style {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    width: rem(1080);
    height: 90vh;
    border-radius: rem(10);
    background-color: $white;
    transform: translate(-50%, -50%);

    > * {
        flex-shrink: 0;
    }

    .manage-nav {
        position: relative;
        z-index: 1;
        margin-bottom: 0;
        // padding: rem(40) rem(40) 0;
        background-color: $gray-0;
        // background-color: $white;

        li {
            border-color: transparent;
            padding: rem(4) rem(40);
            line-height: rem(38);
            font-size: rem(16);

            &.active {
                border-left: rem(1) solid $gray-1;
                border-right-color: $gray-1;
            }
        }
    }

    .modal-title {
        @include modal-title-style;
    }

    .modal-content {
        @include modal-content-style;
    }

    .modal-footer {
        @include modal-footer-style;
    }
}
